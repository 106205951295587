import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import { useStaticQuery, graphql } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import { FormattedMessage } from 'react-intl';
import GeneralSettings from 'helpers/constants/settings';

import Links from 'helpers/constants/links';

import cmnStyles from 'styles/pages.module.scss';
import AboutArticle from '../Article';
import animations from '../Animations.module.scss';
import styles from './OnoffGroup.module.scss';

const AboutGroup: React.FC = () => {
  const {
    imageGroup,
  } = useStaticQuery(
    graphql`
        query {
            imageGroup: file(relativePath: { eq: "about/group.png" }) {
                childImageSharp {
                    fluid(maxWidth: 750) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64,
                    }
                }
            }
        }
    `,
  );

  const visitPage = GeneralSettings.ABOUT.ENABLE_VISIT_PAGE ? (
    <p className={cmnStyles.article}>
      <FormattedMessage
        id="components.about.group2"
        values={{
          a: (...chunks: string[]): JSX.Element => (
            <a
              href={Links.PAGES.GROUP_MAIN}
              className={styles.link}
            >
              {chunks}
            </a>
          ),
        }}
      />
    </p>
  ) : null;
  return (
    <Container className={styles.container}>
      <Row>
        <Col
          xs={{ span: 12, order: 2 }}
          sm={{ span: 12, order: 2 }}
          md={{ span: 12, order: 2 }}
          lg={{ span: 6, order: 1 }}
          xl={{ span: 6, order: 1 }}
          className={cmnStyles.alignSectionVerticallyCenter}
        >
          <AboutArticle classOverride={styles.mainText}>
            <FormattedMessage id="components.about.group1" />
          </AboutArticle>
          {visitPage}
        </Col>
        <Col
          xs={{ span: 12, order: 1 }}
          sm={{ span: 12, order: 1 }}
          md={{ span: 12, order: 1 }}
          lg={{ span: 6, order: 2 }}
          xl={{ span: 6, order: 2 }}
        >
          <div className={animations.onoffGroupImage}>
            <GatsbyImage fluid={imageGroup.childImageSharp.fluid} />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default AboutGroup;
