import React from 'react';

import SEO from 'components/seo';
import Index, { IndexProps } from 'components/Layout';
import PageWrapper from 'components/PageWrapper';

import AboutIntro from 'components/About/Intro';
import AboutTechnologies from 'components/About/Techs';
import AboutGroup from 'components/About/OnoffGroup';
import BannerTransform from 'components/Banners/BannerTransform';

const AboutPage: React.FC<IndexProps> = ({ pageContext }) => (
  <Index pageContext={pageContext}>
    <SEO pageTitleTermPath="about" />
    <PageWrapper withBottomBackground>
      <AboutIntro />
      <AboutTechnologies />
      <AboutGroup />
    </PageWrapper>

    <BannerTransform />
  </Index>
);

export default AboutPage;
