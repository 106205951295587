import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import { useStaticQuery, graphql } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import { FormattedMessage } from 'react-intl';

import cmnStyles from 'styles/pages.module.scss';
import AboutArticle from '../Article';
import animations from '../Animations.module.scss';
import styles from './Techs.module.scss';

const AboutTechnologies: React.FC = () => {
  const {
    imageTechs,
  } = useStaticQuery(
    graphql`
        query {
            imageTechs: file(relativePath: { eq: "about/techs.png" }) {
                childImageSharp {
                    fluid(maxWidth: 676) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64,
                    }
                }
            },
        }
    `,
  );
  return (
    <Container className={styles.container}>
      <Row>
        <Col
          xs={{ span: 12, order: 1 }}
          sm={{ span: 12, order: 1 }}
          md={{ span: 12, order: 1 }}
          lg={{ span: 6, order: 1 }}
          xl={{ span: 6, order: 1 }}
        >
          <div className={animations.techsImage}>
            <GatsbyImage fluid={imageTechs.childImageSharp.fluid} />
          </div>
        </Col>
        <Col
          xs={{ span: 12, order: 2 }}
          sm={{ span: 12, order: 2 }}
          md={{ span: 12, order: 2 }}
          lg={{ span: 6, order: 2 }}
          xl={{ span: 6, order: 2 }}
          className={cmnStyles.alignSectionVerticallyCenter}
        >
          <AboutArticle classOverride={styles.mainText}>
            <FormattedMessage id="components.about.technologies" />
          </AboutArticle>
        </Col>
      </Row>
    </Container>
  );
};

export default AboutTechnologies;
